import Moment from "moment";
import vuePDF from "vue-pdf";

export default {
  name: "EstimateProfitShare",
  data() {
    return {
      property: {
        listElement: {
          estimateProfitShare: {
            downloading: false,
            message: "",
          },
        },
        modal: {
          showModalPreviewPDFEstimateProfitShare: false,
        },
        animation: {
          estimateProfitShare: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
      },
      pdf: {
        src: null,
        pdfToDownload: null,
        numPages: 0,
      },
      loading: false,
      options: {
        office: [
          { value: false, text: "Semua Cabang" },
          { value: true, text: "Per Cabang" },
        ],
      },
      table: {
        data: {
          estimateProfitShare: [],
        },
        header: {
          estimateProfitShare: null,
        },
      },
      dataForm: {
        dateNow: "",
        period: "",
        isByOffice: false,
      },
    };
  },
  methods: {
    periodConvertMonth(val) {
      Moment.locale("ID");
      return `${Moment(val).format("YYYY")}-${Moment(val).format("MM")}`;
    },
    setupDefaultDate() {
      this.dataForm.dateNow = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.period = new Date();
    },
    async onDownload(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        period: this.periodConvertMonth(this.dataForm.period),
        fileType: fileType,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/profit-share-estimate-by-product/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          var a = document.createElement("a"),
            url = URL.createObjectURL(resp.data);
          a.href = url;
          a.download = `Hasil_Estimasi_Bagi_Hasil.${fileType}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Hasil_Estimasi_Bagi_Hasil.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getReportEstimateProfitShare() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.listElement.estimateProfitShare.message = "";
      this.property.animation.estimateProfitShare.isLoading = true;
      this.property.listElement.estimateProfitShare.downloading = true;
      this.table.data.estimateProfitShare = [];
      this.table.header.estimateProfitShare = null;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/estimate-profit-share",
          payload: {
            period: this.dataForm.period,
          },
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.estimateProfitShare = resp.data.data.details;
            this.table.header.estimateProfitShare = resp.data.data.header;
          }, 1000);
        } else {
          this.property.listElement.estimateProfitShare.message =
            resp.data.message;
        }
      } catch (error) {
        this.table.data.estimateProfitShare = [];
        this.property.listElement.estimateProfitShare.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.property.animation.estimateProfitShare.isLoading = false;
          this.property.listElement.estimateProfitShare.downloading = false;
        }, 1000);
      }
    },
    async processReportEstimateProfitShare() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Data ?",
          type: "is-success",
          onConfirm: async () => {
            this.pdf.src = null;
            this.pdf.numPages = 0;
            this.pdf.pdfToDownload = null;
            this.property.animation.isDownloadingFile = true;
            try {
              const payload = {
                period: this.periodConvertMonth(this.dataForm.period),
                fileType: "pdf",
              };
              const response = await this.$store.dispatch({
                type: "GET_SAVE_RESPONSE_DATA",
                endPoint: "report",
                reqUrl: "report/profit-share-estimate-by-product/files",
                payload: payload,
              });
              console.log(response);
              const blob = new Blob([response.data]);
              const objectUrl = URL.createObjectURL(blob);
              var load = vuePDF.createLoadingTask(objectUrl);
              this.pdf.pdfToDownload = objectUrl;
              this.pdf.src = load;
              this.pdf.src.promise.then((pdf) => {
                this.pdf.numPages = pdf.numPages;
              });
              this.property.modal.showModalPreviewPDFEstimateProfitShare = true;
            } catch (error) {
              this.pdfsrc = null;
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.isDownloadingFile = false;
              }, 1000);
            }
          },
        });
      }
    },
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/profit-share-estimate/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          let a = document.createElement("a");
          const blobresp = new Blob([resp.data], { type: "application/pdf" });
          let url = URL.createObjectURL(blobresp);
          a.href = url;
          a.target = "_blank";
          a.click();
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_End_Of_Day.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async postProfitShareEstimate() {
      const confirm = await this.simpleConfirmation(
        "Update Estimasi Bagi Hasil ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.loading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl:
            "close-end-of-month-centralize/generate-current-profit-share-estimate",
          endPoint: "process",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.dialog.alert({
            title: "Success !",
            message: "Update Estimasi Bagi Hasil Berhasil Di Lakukan. ",
            confirmText: "Ok",
            type: "is-success",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.setupDefaultDate();
  },
};
